import { useForm } from 'react-hook-form'

import { Navigation, Input } from '@itau-loans-www/shopping/src/components'
import ProposalLayout from 'layouts/Proposal'

import './desired-loan-data.scss'
import { useDesiredLoanData } from './useDesiredLoanData'

const Customize = ({ pageContext }) => {
  const initialValues = {
    customize: ''
  }

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  const { onSubmit, loading } = useDesiredLoanData()

  return (
    <ProposalLayout pageContext={pageContext}>
      <h1 className="proposal-title">
        Qual o valor do empréstimo que você precisa?
      </h1>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <Input.Currency
          label="Informe o valor necessário"
          id="customize"
          type="currency"
          name="customize"
          data-cy="cy-input-edit-value"
          rules={{
            min: 400
          }}
          control={control}
        />
        <Navigation
          submit
          nextStep
          isNextStepButtonLoading={loading}
          isNextButtonDisabled={!isValid}
          nextButtonId="desired-loan-data-customize-next-btn"
        />
      </form>
    </ProposalLayout>
  )
}

export default Customize
