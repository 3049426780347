import { useEffect, useState } from 'react'

import { useLead } from '@itau-loans-www/shopping/src/hooks'
import analytics from '@mobi/libraries/analytics'
import { formatters, goTo } from 'utils'

import { DDPageError } from 'utils/datadog/events'

import services from '../services'

export const useDesiredLoanData = () => {
  const [loading, setLoading] = useState(false)
  const { changeLeadData } = useLead()

  const { number } = formatters

  useEffect(() => {
    services
      .getDesiredLoanData()
      .then((data) => {
        changeLeadData({ previousStep: data?.previous_step })
      })
      .catch((error) => {
        DDPageError({
          name: 'desiredLoanData | getDesiredLoanData',
          payload: { error }
        })
      })
  }, [])

  useEffect(() => {
    analytics.track('pageLoad', { currentPage: 'formulario-valor-emprestimo' })
  }, [])

  const onSubmit = async (payload) => {
    setLoading(true)

    const { customize } = payload

    const requestBody = {
      desired_loan_value: number.currencyToFloat(customize)
    }

    analytics.track('buttonClick', {
      currentPage: 'valor-emprestimo',
      detail: 'click-step-valor-emprestimo',
      customLayer: {
        value: customize
      }
    })

    return services
      .putDesiredLoanData(requestBody)
      .then(({ next_step }) => goTo(next_step))
      .catch((error) => {
        DDPageError({
          name: 'desiredLoanData | putDesiredLoanData',
          payload: { error }
        })
      })
      .finally(() => setLoading(false))
  }

  return { onSubmit, loading }
}
